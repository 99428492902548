* {
  outline: none;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

html,
body,
#root {
  font-family: "Futura-Medium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0!important;
  box-sizing: border-box;
}

#root {
  position: relative;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  background-size: cover;
  font-family: "Futura-Medium";
  transition: all ease 0.33ms;
  color: #FFFFFF;
}

.font-family-Futura-Bold {
	font-family: "Futura-Bold";
}

.MuiButtonBase-root {
	display: flex;
	align-items: center;
}

.container {
  width: 1440px;
  max-width: 100%;
}

.container2 {
  width: 1020px;
  max-width: 100%;
}

.ml-8 {
  margin-left: 8px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-18 {
  margin-left: 18px;
}

.ml-30 {
  margin-left: 30px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-40 {
  margin-top: 40px;
}

.font-12 {
	font-size: 12px;
}

.font-14 {
	font-size: 14px;
  line-height: 20px;
}

.font-16 {
	font-size: 16px;
  line-height: 22px;
}

.font-18 {
  font-size: 18px;
  line-height: 24px;
}

.font-20 {
  font-size: 20px;
  line-height: 28px;
}

.font-24 {
  font-size: 24px;
}

.font-36 {
  font-size: 36px;
}

.font-48 {
  font-size: 48px;
}

.color1 {
  color: #FFAD4E;
}

.color2 {
  color: rgba(255, 255, 255, 0.5);
}

.color3 {
  color: #FFA509;
}

.color4 {
  color: #058EFF;
}

.color5 {
  background-image: linear-gradient(to bottom,#EA7D1E,#8F4504);
  background-clip:text;
  -webkit-background-clip:text;
  color: transparent;
}

.color6 {
  color: #489BFF;
}

.color7 {
  color: #F07D18;
}

.color8 {
  color: #009DFF;
}

.color9 {
  color: #E1781C;
}

.color10 {
  background-image: linear-gradient(to bottom,#B5B5B5,#4D4D4D);
  background-clip:text;
  -webkit-background-clip:text;
  color: transparent;
}

.color11 {
  color: rgba(255, 255, 255, 0.8);
}

.color12 {
  color: #FFA200;
}

.color13 {
  color: #FFFFFF;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
}
@media only screen and (max-width: 1020px) {
  .container {
    width: 375px;
  }
  .container2 {
    
  }
}