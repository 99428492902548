.header-view {
	width: 100vw;
	height: 600px;
	background: url(../../assets/images/banner.png);
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;

	.header {
		height: 70px;

		.menuList {
			.menuItem {
				margin-left: 76px;

				&:first-child {
					margin-left: 0;
				}

				.ido {
					.idoDesc {
						margin: 0 8px;
					}

					.circular {
						width: 4px;
						height: 4px;
						background: #FFA509;
						border-radius: 50%;
					}
				}
			}
		}
	}

	.enterApp {
		width: 140px;
		height: 48px;
		border: #BC7B31 2px solid;

		&:hover {
			cursor: pointer;
		}
	}

	.title {
		margin-top: 86px;
	}

	.descList {
		.descItem-r {
			margin-left: 44px;
		}
	}

	.footer {
		position: absolute;
		bottom: 0;
		width: 100%;

		.footer-t,
		.footer-b {
			height: 48px;
		}

		.footer-t {
			background: rgba(255, 255, 255, 0.1);

			.toLeft {
				-webkit-animation: cloud-transit infinite ease-in-out 10s;
				animation: cloud-transit infinite ease-in-out 10s;
				// width: 100vw;
				// height: 300px;
				// background: url(../.../assets/images/.footer-t);
				// background-size: cover;
				// background-repeat: no-repeat;
			}
		}

		.footer-b {
			background: rgba(255, 255, 255, 0.2);

			.toRight {
				-webkit-animation: cloud-transit-2 infinite linear 10s normal;
				animation: cloud-transit-2 infinite linear 10s normal;
			}
		}
	}
}

.MuiLink-root.active {
	text-decoration: none !important;
}

@media only screen and (max-width: 1020px) {
	.header-view {
		background: url(../../assets/images/banner-m.png);

		.header-m {
			height: 88px;
			padding-left: 20px;
			padding-right: 24px;
		}

		.enterApp {
			width: 90px;
			height: 40px;
		}

		.title {
			margin-top: 60px;
			font-size: 36px;
		}

		.title2 {
			margin-top: 8px;
			font-size: 30px;
			flex-wrap: wrap;
			text-align: center;
		}

		.descList {
			margin-top: 66px;
			flex-direction: column;
			padding-left: 10px;

			.descItem-r {
				margin-left: 0;
			}
		}
	}

	.menu-view {
		width: 100vw;
		height: 100vh;
		background: url(../../assets/images//menuBg.png);
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;

		.menuList {
			margin-top: 136px;

			.menuItem {
				display: block;
				width: 300px;
				height: 48px;
				line-height: 48px;
				background: #021C4D;
			}
		}

		.close {
			position: absolute;
			bottom: 44px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

@media only screen and (max-width: 321px) {
	.header-view {
		.header-m {
			padding-left: 10px;
			padding-right: 12px;

			.logo {
				height: 48px;
			}
		}

		.enterApp {
			width: 80px;
		}
	}
}

.MuiAlert-filledSuccess {
	padding: 0 100px;
	background: linear-gradient(78deg, #1070E5 0%, #0B386F 100%);
}

.MuiAlert-action {
	display: none !important;
}

.MuiAlert-root {
	padding: 6px 60px !important;
}

@keyframes cloud-transit {
	from {

		transform: translateX(100%);

		-webkit-transform: translateX(100%);

	}

	100% {

		transform: translateX(-100%);

		-webkit-transform: translateX(-100%);

	}
}

@keyframes cloud-transit-2 {
	0% {

		transform: translateX(-100%);

		-webkit-transform: translateX(-100%);

	}

	100% {

		transform: translateX(100%);

		-webkit-transform: translateX(100%);

	}
}